<template>
  <div class="MessageDetailList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="false"
    >
      <template #searchSlot>
        <v-datepicker label="创建时间段" :startTime.sync="searchParams.regFromDate" :endTime.sync="searchParams.regToDate" type="rangedatetimer" defaultTimeType="week"></v-datepicker>
        <v-input label="手机号" v-model="searchParams.mobile"></v-input>
        <v-select label="短信分类" v-model="senderSecondType" :optionGroup="true" :options="senderSecondTypeOps" @change="senderSecondTypeChange"></v-select>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams"></v-select2>
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams, regionParams } from 'common/select2Params'
import { getListURL, exportListURL } from './api'
import { senderSecondTypeOps, senderTypesMap, senderSecondTypeMap, actualStatusMap } from './map'
import { createAlinkVNode } from 'common/vdom'
export default {
  name: 'MessageDetailList',
  data () {
    let _this = this
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      senderSecondTypeOps,
      communityParams,
      regionParams,
      senderSecondType: undefined,
      searchParams: {
        regFromDate: '',
        regToDate: '',
        mobile: '',
        senderSecondType: undefined,
        communityId: '',
        regionId: ''
      },
      headers: [
        {
          prop: 'mobile',
          label: '关联账号'
        },
        {
          prop: 'content',
          label: '内容',
          formatter: (row, prop) => {
            let opts = {
              cb: _this.aletrContent
            }
            return createAlinkVNode(this, row, prop, opts)
          }
        },
        {
          prop: 'sendType',
          label: '短信分类',
          formatter (row) {
            let secondVal = `${row.sendType}-${row.senderSecondType}`
            return `${senderTypesMap[row.sendType]}- ${senderSecondTypeMap[secondVal] || ''}`
          }
        },
        {
          prop: 'sendCount',
          label: '计算短信条数'
        },
        {
          prop: 'actualStatus',
          label: '实际发送状态',
          formatter (row) {
            let status = row.actualStatus >= 2 ? 2 : row.actualStatus
            return actualStatusMap[status]
          }
        },
        {
          prop: 'actualCount',
          label: '实际发送短信条数'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  mounted () {
    if (this.searchParams.senderSecondType && this.searchParams.senderType) {
      this.senderSecondType = `${this.searchParams.senderType}-${this.searchParams.senderSecondType}`
    }
  },
  methods: {
    aletrContent (row) {
      this.$alert(row.content)
    },
    senderSecondTypeChange (val, opt) {
      if (val) {
        let arr = val.split('-')
        this.searchParams.senderSecondType = arr[1]
        this.searchParams.senderType = arr[0] === '1' ? 1 : undefined
      } else {
        this.searchParams.senderSecondType = undefined
        this.searchParams.senderType = undefined
      }
    }
  }
}
</script>
