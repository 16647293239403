// 获取列表
// const getListURL = `${API_CONFIG.baseURL}smsAction!list.action`  // 旧接口
const getListURL = `${API_CONFIG.butlerBaseURL}sms/pageList`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}sms/export/detail/list`

export {
  getListURL,
  exportListURL
}
