import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 短信分类
const senderSecondTypeOps = [
  {
    label: '请选择',
    options: [
      {
        'label': '全部',
        'value': undefined
      }
    ]
  },
  {
    label: '系统',
    options: [
      {
        'label': '注册验证',
        'value': '0-1'
      }, {
        'label': '设置支付密码',
        'value': '0-2'
      }, {
        'label': '设置登录密码',
        'value': '0-3'
      }, {
        'label': '修改手机号',
        'value': '0-4'
      }, {
        'label': '找回密码',
        'value': '0-5'
      }, {
        'label': '系统告警',
        'value': '0-6'
      }, {
        'label': '商家入驻',
        'value': '0-7'
      }, {
        'label': '访客申请',
        'value': '0-8'
      }, {
        'label': '登录验证',
        'value': '0-9'
      }, {
        'label': '更换组织超管',
        'value': '0-10'
      }, {
        'label': '用户注销',
        'value': '0-22'
      }, {
        'label': '战疫',
        'value': '0-23'
      },
      {
        'label': '表单短信',
        'value': '0-24'
      },
      {
        'label': '信息推送',
        'value': '0-27'
      }
    ]
  }, {
    label: '服务',
    options: [
      {
        'label': '寄存录入',
        'value': '1-7'
      }, {
        'label': '寄存领取',
        'value': '1-8'
      }, {
        'label': '寄存超时',
        'value': '1-9'
      }, {
        'label': '外赠失效',
        'value': '1-10'
      }, {
        'label': '报事报修',
        'value': '1-16'
      }, {
        'label': '物品借用',
        'value': '1-20'
      }
    ]
  }, {
    label: '快递',
    options: [
      {
        'label': '快递录入',
        'value': '2-11'
      }, {
        'label': '快递领取',
        'value': '2-12'
      }, {
        'label': '快递超时',
        'value': '2-13'
      }, {
        'label': '快递取消',
        'value': '2-14'
      }
    ]
  }, {
    label: '第三方',
    options: [
      {
        'label': '快递柜系统',
        'value': '3-15'
      }
    ]
  }
]

// 短信分类一级
const senderTypesMap = {
  0: '系统',
  1: '服务',
  2: '快递',
  3: '第三方'
}

const senderSecondTypeMapFun = () => {
  let typeArr = []
  senderSecondTypeOps.forEach((item, index) => {
    if (index !== 0) {
      typeArr = [
        ...typeArr,
        ...item.options
      ]
    }
  })
  let typeMap = {}
  typeArr.forEach(item => {
    typeMap[item.value] = item.label
  })
  return typeMap
}
const senderSecondTypeMap = senderSecondTypeMapFun()

// 实际发送状态
const actualStatusMap = {
  0: '',
  1: '短消息发送成功',
  2: '短消息发送失败'
}
export {
  statusMap,
  setStatusOps,
  senderSecondTypeOps,
  senderTypesMap,
  senderSecondTypeMap,
  actualStatusMap
}
